<template>
  <div>
    <div class='relative'>
    <div
      class="px-4 py-4 lg:py-6 h-[550px] overflow-y-auto"
      data-simplebar
      ref="messageContainer"
      v-if="userInfo"
    >
      <div class="flex flex-col gap-3 lg:gap-6">
        <MessageGroup
          v-for="(messageGroup, index) in messages"
          :key="messageGroup.id"
          :messageGroup="messageGroup"
          :topicLabel="topic.label"
          :userName="getUserName()"
          :isLast="index === messages.length - 1"
        />
        <transition name="inner-fade" mode="out-in">
          <div
            v-if="showQuestions"
            class="flex flex-col items-end gap-2.5 text-xs lg:text-sm mb-2"
          >
            <button
              v-for="item in questions"
              :key="item.order"
              class="px-4 py-1.5 border border-primary-light rounded-lg text-primary-main hover:text-white hover:bg-primary-main hover:border-primary-main"
              @click.prevent="handleSendMessage(item.question)"
            >
              {{ item.question }}
            </button>
          </div>
        </transition>
      </div>
      <div v-if="error" class="mt-2 p-2 w-full error-message justify-center">
        <p>{{ $t(`errors.${error}`, { messages: errorParams[0] }) }}</p>
        <button @click="closeError">{{ $t('chatBoard.dismissButton') }}</button>
      </div>

    </div>
    <button
    v-if="!isAtBottom"
        @click="scrollToBottomButtonClicked"
        class="absolute bottom-20 left-1/2 w-10 h-10 items-center justify-center bg-primary-main border-0 rounded-lg text-white cursor-pointer hover:bg-primary-main hover:text-white active:brightness-95"
      >
      <i class="fa-solid fa-arrow-down text-2xl"></i>
      </button>
  </div>
     <!-- Scroll to Bottom Button -->
     <ChatInput @sendMessage="sendMessage" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { nextTick } from 'vue';
import MessageGroup from './MessageGroup.vue';
import * as api from '../../api';
import socketManager from '../../socketManager';
import * as Constants from '../../constants';
import ChatInput from './ChatInput.vue';

export default {
  name: 'ChatBoard',
  data() {
    return {
      room: null,
      isLoadingMessages: false,
      isWriting: false,
      waitingForIntro: true,
      needToScroll: false,
      errorParams: [],
      error: null,
      messages: [],
      enableSend: false,
      isRecording: false,
      showScrollButton: false,
      showDisclaimer: false,
      requireAgreement: false,
      questionsVisible: true,
      isTopic: false,
      localQuestion: this.question,
      questions: this.getRandomQuestions(),
      isAtBottom: true,
      showScrollToBottomButton: false,
    };
  },
  components: {
    MessageGroup,
    ChatInput,
  },
  computed: {
    ...mapState({
      errorParams: (state) => state.chat.errorParams,
      error: (state) => state.chat.error,
      userInfo: (state) => state.userInfo,
    }),
    showQuestions() {
      return this.questions.length > 0 && this.questionsVisible;
    },
  },
  props: {
    topic: Object,
    id: String,
    type: String,
  },
  watch: {
    messages: {
      handler() {
        this.scrollToBottom();
      },
      deep: true,
    },
    error: {
      handler() {
        this.scrollToBottom();
      },
      deep: true,
    },
  },
  async mounted() {
    this.scrollToBottom();
    await this.fetchData();

    // Remove question query param from URL
    if (this.$route.query.question) {
      this.$router.replace({ query: {} });
    }

    // Function to try sending the question
    const trySendQuestion = () => {
      if (this.localQuestion && !this.waitingForIntro) {
        const questionText = this.topic.questions[this.localQuestion - 1].question;
        this.handleSendMessage(questionText);

        // Reset the localQuestion to ensure it's only sent once
        this.localQuestion = null;
      }
    };

    // Initial attempt to send the question
    trySendQuestion();

    // Watch for changes in `waitingForIntro` and try sending the question when ready
    this.$watch(
      () => this.waitingForIntro,
      (newValue) => {
        if (!newValue) {
          trySendQuestion();
        }
      },
    );

    if (this.$refs.messageContainer) {
      this.$refs.messageContainer.addEventListener('scroll', this.handleScroll);
    }
  },
  beforeUnmount() {
    if (this.$refs.messageContainer) {
      this.$refs.messageContainer.removeEventListener('scroll', this.handleScroll);
    }
  },
  methods: {
    scrollToBottom() {
      nextTick(() => {
        if (this.isAtBottom) {
          const container = this.$refs.messageContainer;
          if (container) {
            container.scrollTop = container.scrollHeight;
          }
        }
      });
    },
    scrollToBottomButtonClicked() {
      const container = this.$refs.messageContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
      this.isAtBottom = true;
      this.showScrollToBottomButton = false;
    },
    handleScroll() {
      const container = this.$refs.messageContainer;
      if (container) {
        const threshold = 20; // Pixels from bottom to consider as "at bottom"
        const isAtBottom = container.scrollTop + container.clientHeight >= container.scrollHeight - threshold;
        this.isAtBottom = isAtBottom;
        this.showScrollToBottomButton = !isAtBottom;

        console.log('isAtBottom', isAtBottom);
      }
    },
    getUserName() {
      return `${this.userInfo.firstName} ${this.userInfo.lastName}`;
    },
    closeError() {
      this.$store.dispatch('chat/clearError');
    },
    // chat
    getRandomQuestions() {
      if (!this.topic || !this.topic.questions) {
        return [];
      }
      // topic.questions are the questions
      // return 3 random questions
      const questions = this.topic.questions;
      const selectedQuestions = [];
      while (selectedQuestions.length < 3) {
        const randomIndex = Math.floor(Math.random() * questions.length);
        const question = questions[randomIndex];
        if (!selectedQuestions.includes(question)) {
          selectedQuestions.push(question);
        }
      }
      console.log(selectedQuestions);
      return selectedQuestions;
    },
    async sendMessage(message, images, files) {
      this.questionsVisible = false;
      // We need generate a unique id for the message to be able to pair it with with confirmation message
      const imagesUrls = [];
      const fileUrLs = [];

      const messageToSend = await api.createEmptyMessage(this.room._id);

      messageToSend.messages = [
        {
          content: message,
          timestamp: new Date().toISOString(),
        },
      ];

      messageToSend.status = Constants.PENDING_STATUS;

      this.messages.push(messageToSend);

      // handle images
      if (images != null && images.length > 0) {
        for (const image of images) {
          const imageData = {
            name: image.name,
            path: image.url,
            originalName: image.name,
          };

          const imageUrl = await api.uploadImageForRoom(
            imageData,
            this.room._id,
            messageToSend._id,
          );
          imagesUrls.push(imageUrl);
        }

        // set images status to uploaded
        this.updateMessageImages({ id: messageToSend._id, images: imagesUrls });
      }

      if (files != null && files.length > 0) {
        for (const file of files) {
          // file is type of File
          const fileData = {
            name: file.name,
            originalName: file.name,
            file,
          };

          const fileUrl = await api.uploadFileForRoom(
            fileData,
            this.room._id,
            messageToSend._id,
          );
          fileUrLs.push(fileUrl);
        }

        // set images status to uploaded
        this.updateMessageFiles({ id: messageToSend._id, files: fileUrLs });
      }

      // clear text field
      socketManager.emit('send-message', messageToSend);
    },
    updateMessageImages({ id, images }) {
      const message = this.messages.find((msg) => msg._id === id);
      if (message) {
        message.images = images;
      }
    },
    updateMessageFiles({ id, files }) {
      const message = this.messages.find((msg) => msg._id === id);
      if (message) {
        message.files = files;
      }
    },
    async fetchData() {
      console.log(this.type);
      try {
        if (this.type === 'guru') {
          this.room = await api.getRoomByRelatedId('guru');
        } else {
          this.room = await api.getRoomByRelatedId('topic', this.id);
        }
        this.connectToSocket(this.room);
      } catch (error) {
        console.error(error);
      }
    },
    async connectToSocket(room) {
      this.room = room;
      console.log('connectToSocket', room._id);

      this.isLoadingMessages = true;

      await socketManager.connect();
      socketManager.addEventListener('init-chat', (data) => this.handleInitChat(data));

      socketManager.addEventListener('confirm-message', (data) => this.confirmMessage(data));

      socketManager.addEventListener('new-message', (data) => this.handleNewMessage(data));
      socketManager.addEventListener('write-message', (data) => this.handleWriteMessage(data));
      socketManager.addEventListener('finish-message', (data) => this.handleFinishMessage(data));
      socketManager.addEventListener('run-action', (data) => this.handleActionButton(data));
      socketManager.addEventListener('run-action-redirect', (data) => this.handleActionButton(data));
      socketManager.addEventListener('function-call-start', (data) => this.handleFunctionCallStart(data));
      socketManager.addEventListener('function-call-finish', (data) => this.handleFunctionCallFinish(data));
      socketManager.addEventListener('error', (data) => this.handleFunctionCallError(data));

      socketManager.emit('join-room/v2', { roomId: room._id, platform: 'web' });
    },
    handleInitChat(data) {
      this.messages = data.map((item) => item);
      this.isLoadingMessages = false;

      if (this.messages.length > 0) {
        this.waitingForIntro = false;
      }
    },
    handleNewMessage(data) {
      const message = data;
      this.messages.push(message);
      this.needToScroll = true;
    },
    handleWriteMessage(data) {
      this.isWriting = true;
      const lastMessage = this.messages.find((msg) => msg._id === data._id);
      if (lastMessage) {
        lastMessage.messages = data.messages;
        lastMessage.images = data.images;
        lastMessage.attachments = data.attachments;
      }
    },
    handleFinishMessage() {
      this.isWriting = false;

      if (this.messages.length > 0) {
        this.waitingForIntro = false;
      }
    },
    handleActionButton(data) {
      // Add your implementation for handling action button
      // show action button in chat
      this.messages.push({
        type: 'action',
        timestamp: new Date().toISOString(),
        actionData: data,
      });
      this.needToScroll = true;
    },
    confirmMessage(data) {
      // Add your implementation for confirming message
    },
    handleFunctionCallStart(data) {
      // set action to last message
      this.messages[this.messages.length - 1].action = data;
      // Add your implementation for handling function call start
    },
    handleFunctionCallFinish(data) {
      // remove action from last message
      this.messages[this.messages.length - 1].action = null;
      // Add your implementation for handling function call finish
    },
    handleFunctionCallError(data) {
      const params = data.params ? data.params.map((param) => param) : [];
      this.errorParams = params;
      this.error = data.code || 'Unknown error';
    },
    clearError() {
      this.error = null;
      this.errorParams = [];
    },
    handleSendMessage(question) {
      // Additional logic when sending a message can be placed here
      this.sendMessage(question);
      this.questionsVisible = false;
      // Access the ChatBoard instance and toggle questions visibility
    },
  },
};
</script>

<style scoped>
.error-message {
  color: #fff;
  padding: 10px;
  border: 3px solid #721c24;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.error-message button {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #9537f8;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.inner-fade-enter-active,
.inner-fade-leave-active {
  transition: opacity 0.2s ease;
}

.inner-fade-enter-from,
.inner-fade-leave-to {
  opacity: 0;
}
.bottom-20 {
  bottom: 20px;
}
</style>
